import React from "react"

import useImages from "../hooks/useImages"
import useViewportSize, { SIZES } from "../hooks/useViewportSize"

import Layout from "../components/layout"
import Section from "../components/section"
import Separator from "../components/separator"

export default function WeddingPlanner() {
  const { hero } = useImages()
  const viewportSize = useViewportSize()
  const heroContent = <></>

  return (
    <Layout
      heroImg={hero.childImageSharp}
      heroContent={heroContent}
      heroHeight={viewportSize === SIZES.XS ? "100px" : "250px"}
    >
      <Section className={"text-block"}>
        <h2>Wedding planner</h2>
        <Separator />
        <p>
          Organizar una boda no es fácil, os lo podemos asegurar. Para nuestra boda hemos decidido trabajar con Judit y gracias a ella la organización está siendo mucho más sencilla. No dudéis en contactar con ella para cualquier cosa referente a la boda o para organización de eventos. Aqui  os facilitamos su telefono y web: 691870721 <a target="_blank" rel="noopener" href="www.ritabyju.com">www.ritabyju.com</a>
        </p>
      </Section>
    </Layout>
  )
}
